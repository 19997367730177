<template>
  <div class="institute">
    <Top active='6'/>
    <div class="insBox">
      <div class="content">
        <div class="title">
          <div class="title1">重庆合川三江生态文明研究院</div>
          <div class="title2">
            <span class="textFamily">Sanjiang</span>
            <span class="textFamily">Ecological Civilization Research Institute</span>
          </div>
        </div>
        <div class="insList">
          <div class="insItem" v-for="(item,index) in data" :key="index" @click="toDetail(item.id)">
            <img :src="item.villas_image" alt="">
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'institute',
  components: {
    Top,
    Bottom
  },
  data() {
    return {
      data:[],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.startLoading()
      this.axios.post("/app/Villas/villasList").then(res => {
        if (res.data.code === 1) {
          this.data = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toDetail(id){
      this.$router.push({path:'/solutionDetail',query:{id:id,from:2}})
    }
  }
}
</script>

<style scoped>
  .institute{
    padding: 4.167rem 0 6.25rem 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
    color: #ffffff
  }
  .insBox{
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    overflow-y: scroll;
    padding-bottom: 2rem
  }
  .content{
    width: 67.7rem;
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 2.5rem;
  }
  .title{
    text-align:center;
    margin-bottom: 2rem
  }
  .title1{
    font-size: 2.2rem;
    color: #FFFFFF;
    margin-bottom: 1rem
  }
  .title2 span{
    font-size: 2.7rem;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
    margin-right: 1rem
  }
  .insList{
    width: 67.7rem;
  }
  .insItem{
    width: 21.5rem;
    height: 16.67rem;
    background-color: rgba(30,144,220,0.5);
    text-align:center;
    padding-top:0.52rem;
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 2rem;
    cursor: pointer;
  }
  .insItem:hover{
    background-color: rgba(30,144,220,1);
  }
  .insItem img{
    width: 20.5rem;
    height: 12.5rem;
    margin-bottom: 1.09rem
  }
  .insItem p{
    font-size: 1.04rem;
    font-weight: bold;
  }
</style>

