<template>
  <div class="relation">
    <Top active='5'/>
    <div class="relBox">
      <div class="content">
        <div class="relLeft">
          <p class="relLeftTxt1">年度报告</p>
          <p class="relLeftTxt2 textFamily">Annual report</p>
          <div class="leftBox">
            <div class="leftItem leftItem1">
              <p>挂牌<br />新三板</p>
              <div>2015</div>
            </div>
            <img class="leftImg1" src="../assets/img/xian1.png" alt="">
            <div class="leftItem leftItem2">
              <p>新三板<br />创新层</p>
              <div>2020</div>
            </div>
            <img class="leftImg2" src="../assets/img/xian1.png" alt="">
            <div class="leftItem leftItem3">
              <p>更高层级<br />资本市场</p>
              <div>未来</div>
            </div>
          </div>
        </div>
        <div class="relRight">
          <div class="rightItem" v-for="(item,index) in data" :key="index">
            <div class="rightTxt1">{{item.year}}</div>
            <div class="rightTxt2">
              <p>{{item.title}}</p>
              <p>{{item.sub_title}}</p>
            </div>
            <img class="rightImg" src="../assets/img/right.png" alt="">
          </div>
        </div>
      </div>
      
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'relation',
  components: {
    Top,
    Bottom
  },
  data() {
    return {
      data:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.startLoading()
      this.axios.post("/app/Annual/annualReportList").then(res => {
        if (res.data.code === 1) {
          this.data = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toReport(i){
      window.open(i)
    }
  }
}
</script>

<style scoped>
  .relation{
    padding: 4.167rem 0 6.25rem 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
    color: #ffffff
  }
  .relBox{
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    overflow-y: scroll;
  }
  .content{
    width: 67.7rem;
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between
  }
  .relLeft{
    width:23.7rem;
    height: 38.9rem;
    background-image: url('../assets/img/baogao1.png');
    background-size:100% 100%;
    padding: 3.33rem 1.8rem;
  }
  .relLeftTxt1{
    font-size: 3.125rem;
    font-weight: bold;
    color: #1E90DC;
    margin-bottom: 1rem
  }
  .relLeftTxt2{
    font-size: 3.125rem;
    font-family: Arial;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 2rem
  }
  .leftBox{
    width: 100%;
    height: 11rem;
    display: flex;
  }
  .leftImg1{
    width: 4.6rem;
    height: 2.66rem;
    margin-top: 7.56rem;
  }
  .leftImg2{
    width: 4.58rem;
    height: 4.06rem;
    margin-top: 3.6rem;
  }
  .leftItem{
    width: 3.6rem;
    font-size: 1.04rem;
    white-space: nowrap;
  }
  .leftItem p{
    font-size: 0.8rem;
    text-align:center;
    margin-bottom: 0.8rem
  }
  .leftItem div{
    font-size: 1.04rem;
    width: 3.5rem;
    line-height:1.93rem;
    text-align:center;
    background-color: #1E90DC
  }
  .leftItem1{
    margin-top: auto
  }
  .leftItem2{
    margin-top: 3.5rem
  }
  .leftItem3{
    margin-top: 0
  }
  .relRight{
    width: 41.5rem;
    height: 38.9rem;
    overflow-y: scroll
    /* display: flex;
    flex-direction: column;
    justify-content: space-between */
  }
  .rightItem{
     width: 41.5rem;
     height: 6.25rem;
     background: rgba(30, 144, 220, 0.5);
     display: flex;
     align-items: center;
     margin-bottom: 1.9rem;
  }
  .rightTxt1{
    width: 10.4rem;
    line-height: 6.25rem;
    text-align:center;
    font-size: 2.08rem;
    font-weight: bold;
    margin-right: 2.6rem
  }
  .rightTxt2 p:nth-child(1){
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem
  }
  .rightTxt2 p:nth-child(2){
    font-size: 1.04rem
  }
  .rightImg{
    width: 2.08rem;
    height: 2.08rem;
    margin-left: auto;
    margin-right: 2.08rem
  }
</style>

