<template>
  <div class="solutionList">
    <Top  active='3'/>
    <div class="solBox">
      <div class="content water" v-if="type==1">
        <div class="title">
          <div class="titleTxt1" v-if="id==5">水环境</div>
          <div class="titleTxt1" v-if="id==4">环境咨询</div>
          <div class="titleTxt1" v-if="id==3">土壤环境</div>
          <div class="titleTxt1" v-if="id==2">环境监测</div>
          <div class="titleTxt1" v-if="id==1">智慧环保</div>
          <div class="titleTxt2" v-if="id==5">
            <span class="textFamily">Water</span>
            <span class="textFamily">environment</span>
          </div>
          <div class="titleTxt2" v-if="id==4">
            <span class="textFamily">Environmental</span>
            <span class="textFamily">consultation</span>
          </div>
          <div class="titleTxt2" v-if="id==3">
            <span class="textFamily">Soil</span>
            <span class="textFamily">environment</span>
          </div>
          <div class="titleTxt2" v-if="id==2">
            <span class="textFamily">Environmental</span>
            <span class="textFamily">monitoring</span>
          </div>
          <div class="titleTxt2" v-if="id==1">
            <span class="textFamily">WISDOM</span>
            <span class="textFamily">ENVIRONMENT</span>
          </div>
        </div>
        <div class="contentBox">
          <div class="contentItem" v-for="(item,index) in data" :key="index" @click="toDetail(item.id,item.project_name,item.is_low)">
            <img :src="item.project_image" alt="">
            <p>{{item.project_name}}</p>
          </div>
        </div>
      </div>

      <div class="content water" v-if="type==2">
        <div class="title">
          <div class="titleTxt1">{{project_name}}</div>
        </div>
        <div class="contentBox">
          <div class="contentItem" v-for="(item,index) in data2" :key="index" @click="toDetail(item.id)">
            <img :src="item.project_image" alt="">
            <p>{{item.project_name}}</p>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'solutionList',
  components: {
    Top,
    Bottom
  },
  data() {
    return {
      id:this.$route.query.id,
      data:[],
      data2:[],
      project_name:'',
      type:1,
    }
  },
  mounted(){
    this.getSolution()
  },
  methods: {
    getSolution(){
      this.startLoading()
      this.axios.post("/app/Solution/solutionProjectList",{
        type_id:this.id
      }).then(res => {
        if (res.data.code === 1) {
          this.data = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toDetail(id,name,low){
      if(low==1){
        this.startLoading()
        this.type=2
        this.project_name=name
        this.axios.post("/app/Solution/solutionTwoList",{
          top_id:id
        }).then(res => {
          if (res.data.code === 1) {
            this.data2 = res.data.data;
            this.endLoading()
          } else {
            this.$message(res.data.msg);
          }
        });
      }else{
          this.$router.push({path:'/solutionDetail',query:{id:id,from:1}})
      }
    }
  }
}
</script>

<style scoped>
  .solutionList{
    padding: 4.167rem 0 6.25rem 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
  }
  .solBox{
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    overflow-y: scroll
  }
  .content{
    width: 67.7rem;
    /* height: 100%; */
    margin: 0 auto;
    padding-bottom: 2rem;
    padding-top: 2.6rem;
  }
  .title{
    text-align:center
  }
  .titleTxt1{
    font-size: 2.6rem;
    color: #FFFFFF;
    margin-bottom: 1rem
  }
  .titleTxt2 span{
    font-size: 3.125rem;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
    margin-right: 1rem
  }
  .contentBox{
    width: 67.7rem;
    margin-top: 2rem;
  }
  .contentItem{
    display: inline-block;
    width: 21rem;
    height: 15rem;
    background-color: rgba(30,144,220,0.5);
    text-align: center;
    padding-top:0.52rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .contentItem:hover{
    background-color: rgba(30,144,220,1);
  }
  .contentItem img{
    width: 20rem;
    height: 12rem;
  }
  .contentItem p{
    font-size:1.04rem;
    color:#fff;
    font-weight: bold;
    margin-top: 0.5rem
  }
</style>

