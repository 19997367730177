<template>
  <div class="solutionDetail">
    <img class="close" @click="$router.back('-1')" src="../assets/img/close.png" alt="">  
    <div class="solBox">
        <img class="banner" v-if="info.ad_image" :src="info.ad_image" alt="">
        <div class="solList">
            <div class="solTitle" v-if="from==1&&info.project_name">{{info.project_name}}</div>
            <div class="solTitle" v-if="from==2&&info.title">{{info.title}}</div>
            <template v-if="from==1">
                <div class="solItem" v-for="(item,index) in data" :key="index">
                    <div class="item2" v-if="info.type==1">
                        <img class="item2Img" :src="item.case_image" alt="" v-image-preview>
                        <div class="item2Txt">
                            <p class="item2Txt1">{{item.case_name}}</p>
                            <div class="item2Txt2" v-if="item.id==50">
                                <p>{{item.case_type}}</p>
                                <p>{{item.case_intro}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="item2" v-if="info.type==2">
                        <div class="item2Txt">
                            <p class="item2Txt1">{{item.case_name}}</p>
                            <div class="item2Txt2" v-if="item.id==50">
                                <p>{{item.case_type}}</p>
                                <p>{{item.case_intro}}</p>
                            </div>
                        </div>
                        <img class="item2Img" :src="item.case_image" alt="" v-image-preview>
                    </div>
                    <div class="item1" v-if="info.type==3">
                        <img class="itemImg" :src="item.case_image" alt="" v-image-preview>
                        <div class="itemTxt">
                            <p class="itemTxt1">{{item.case_name}}</p>
                            <div class="itemTxt2" v-if="item.id==50">
                                <p>{{item.case_type}}</p>
                                <p>{{item.case_intro}}</p>
                            </div>
                        </div>
                    </div>
                <div class="item1" v-if="info.type==4">
                        <div class="itemTxt">
                            <p class="itemTxt1">{{item.case_name}}</p>
                            <div class="itemTxt2" v-if="item.id==50">
                                <p>{{item.case_type}}</p>
                                <p>{{item.case_intro}}</p>
                            </div>
                        </div>
                        <img class="itemImg" :src="item.case_image" alt="" v-image-preview>
                    </div>
                    <div class="item1" v-if="info.type==5">
                        <template v-if="index%2==0">
                            <div class="itemTxt">
                                <p class="itemTxt1">{{item.case_name}}</p>
                                <div class="itemTxt2" v-if="item.id==50">
                                    <p>{{item.case_type}}</p>
                                    <p>{{item.case_intro}}</p>
                                </div>
                            </div>
                            <img class="itemImg" :src="item.case_image" alt="" v-image-preview>
                        </template>
                        <template v-else>
                            <img class="itemImg" :src="item.case_image" alt="" v-image-preview>
                            <div class="itemTxt">
                                <p class="itemTxt1">{{item.case_name}}</p>
                                <div class="itemTxt2" v-if="item.id==50">
                                    <p>{{item.case_type}}</p>
                                    <p>{{item.case_intro}}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            
            <template v-if="from==2">
                <div class="solItem" v-for="(item,index) in data" :key="index">
                    <div class="item2" v-if="info.type==1">
                        <img class="item2Img" :src="item.content_image" alt="" v-image-preview>
                        <div class="item2Txt">
                            <p class="item2Txt1">{{item.content_name}}</p>
                            <div class="item2Txt2">
                                <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="item2" v-if="info.type==2">
                        <div class="item2Txt">
                            <p class="item2Txt1">{{item.content_name}}</p>
                            <div class="item2Txt2">
                                <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                            </div>
                        </div>
                        <img class="item2Img" :src="item.content_image" alt="" v-image-preview>
                    </div>
                    <div class="item1" v-if="info.type==3">
                        <img class="itemImg" :src="item.content_image" alt="" v-image-preview>
                        <div class="itemTxt">
                            <p class="itemTxt1">{{item.content_name}}</p>
                            <div class="itemTxt2">
                                <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                            </div>
                        </div>
                    </div>
                <div class="item1" v-if="info.type==4">
                        <div class="itemTxt">
                            <p class="itemTxt1">{{item.content_name}}</p>
                            <div class="itemTxt2">
                                <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                            </div>
                        </div>
                        <img class="itemImg" :src="item.content_image" alt="" v-image-preview>
                    </div>
                    <div class="item1" v-if="info.type==5">
                        <template v-if="index%2==0">
                            <div class="itemTxt">
                                <p class="itemTxt1">{{item.content_name}}</p>
                                <div class="itemTxt2">
                                    <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                    <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                                </div>
                            </div>
                            <img class="itemImg" :src="item.content_image" alt="" v-image-preview>
                        </template>
                        <template v-else>
                            <img class="itemImg" :src="item.content_image" alt="" v-image-preview>
                            <div class="itemTxt">
                                <p class="itemTxt1">{{item.content_name}}</p>
                                <div class="itemTxt2">
                                    <p><span v-if="id==1">技术支撑：</span>{{item.content_type}}</p>
                                    <p><span v-if="id==1">技术研究：</span>{{item.content_intro}}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'solutionDetail',
  data() {
    return {
      id:this.$route.query.id,
      from:this.$route.query.from,
      info:'',
      data:[]
    }
  },
  mounted(){
      this.getData()
  },
  methods: {
    getData(){
        this.startLoading()
        if(this.from==1){
            this.axios.post("/app/Solution/solutionCaseList",{
                project_id:this.id
            }).then(res => {
                if (res.data.code === 1) {
                this.info = res.data.data.project_info;
                this.data = res.data.data.case_info;
                this.endLoading()
                } else {
                this.$message(res.data.msg);
                }
            });
        }else{
            this.axios.post("/app/Villas/villasDetail",{
                villas_id:this.id
            }).then(res => {
                if (res.data.code === 1) {
                this.info = res.data.data.villa_info;
                this.data = res.data.data.content_info;
                this.endLoading()
                } else {
                this.$message(res.data.msg);
                }
            });
        }
      
    },
  }
}
</script>

<style scoped>
  .solutionDetail{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
  }
  .solBox{
    width: 67.7rem;
    height: 100%;
    margin: 0 auto;
    overflow-y: scroll;
    background-color: #ffffff;
  }
  .close{
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 0;
      right: 13.65rem;
      z-index: 999;
  }
  .banner{
      width: 100%;
      height: 20rem;
      display: block;
  }
  .solList{
      padding: 2.6rem 2.08rem;
  }
  .solTitle{
      font-size: 1.56rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 2rem
  }
  .solItem{
      width: 63.4rem;
      /* height: 13.3rem; */
      margin-bottom: 3rem;
  }
  .item1{
      display: flex;
  }
  .itemTxt{
      width: 31.7rem;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center
  }
  .itemTxt1{
      font-size: 1.25rem;
      font-weight: bold;
      color: #1E90DC;
      margin-bottom: 1rem
  }
  .itemTxt2{
      color: #666666;
      line-height:1.56rem
  }
  .itemImg{
      width: 31.7rem;
      height: 13.3rem;
  }
  .solBox2{
      padding: 3.6rem 2.6rem;
  }
  .item2Txt{   
      width: 100%;
      margin-bottom: 1rem
  }
  .item2Txt1{
      font-size: 1.25rem;
      font-weight: bold;
      color: #1E90DC;
      margin-bottom: 1rem
  }
  .item2Txt2{
      color: #666666;
      line-height:1.56rem
  }
  .item2Img{
      width: 100%;
      margin-bottom: 1rem
  }
</style>

