<template>
  <div class="know">
    <Top  active='2'/>
    <div class="knowBox">
      <div class="nav" :class="{nav2:active==4}">
        <img class="navImg1" v-if="active!=4" src="../assets/img/zhi5.png" alt="">
        <img class="navImg2" v-else src="../assets/img/zhi52.png" alt="">
        <div class="navList" v-if="active!=4">
          <div class="navItem" :class="{active:active==1}" @click="toContent(1)">港力快照</div>
          <div class="navItem" :class="{active:active==2}" @click="toContent(2)">组织架构</div>
          <div class="navItem" :class="{active:active==3}" @click="toContent(3)">团队文化</div>
          <div class="navItem" :class="{active:active==4}" @click="toContent(4)">技术港力</div>
          <div class="navItem" :class="{active:active==5}" @click="toContent(5)">荣誉资质</div>
        </div>
        <div v-else class="navList navList2">
          <div class="navItem" :class="{active:active==1}" @click="toContent(1)">港力快照</div>
          <div class="navItem" :class="{active:active==2}" @click="toContent(2)">组织架构</div>
          <div class="navItem" :class="{active:active==3}" @click="toContent(3)">团队文化</div>
          <div class="navSkill">
            <div class="navItem" @click="toContent(4)">技术港力</div>
            <div class="navSkillTxt">
              <img src="../assets/img/zhi3.png" alt="">
              <div class="navSkillList">
                <div :class="{active:active2==1}" @click="toContent2(1)">研发平台</div>
                <div :class="{active:active2==2}" @click="toContent2(2)">技术团队</div>
                <div :class="{active:active2==3}" @click="toContent2(3)">研发成果</div>
                
              </div>
            </div>
          </div>
          <div class="navItem" :class="{active:active==5}" @click="toContent(5)">荣誉资质</div>
        </div>
      </div>
      <div class="content about" v-show="active==1">
        <div class="aboutLeft">
          <img :src="info.snapshot" alt=""  v-image-preview>
        </div>
        <div class="aboutRight">
          <div class="aboutTitle1">About us</div>
          <div class="aboutTitle2">关于我们</div>
          <div class="line"></div>
          <div class="aboutTxt" v-html="info.about_we"></div>
        </div>
      </div>
      <div class="content structure" v-show="active==2">
        <div class="strTitle1">
          <span class="textFamily">Organizational</span>
          <span style="color:#1E90DC" class="textFamily">structure</span> 
        </div>
        <div class="strTitl2">组织架构</div>
        <img class="strImg" src="../assets/img/zuzhi.9d8545f9.png.png" alt="">
      </div>
      <div class="content culture" v-show="active==3">
        <div class="culTitle">
          <span>团队文化</span>
          <span class="textFamily">- Team culture</span>
        </div>
        <div class="culImg">
          <img :src="culture" alt="">
        </div>
      </div>
      <div class="content skill" v-show="active==4">
        <div class="plate" v-show="active2==1">
          <div class="plateTitle1">
            <span class="textFamily">R&D</span>
            <span style="color: #1E90DC;font-family:'Times New Roman">platform</span>
          </div>
          <div class="plateTitle2">研发平台</div>
          <div class="plateList">
            <div class="plateItem" v-for="(item,index) in plate" :key="index">
              <img :src="item.platform_img" alt="" v-image-preview>
            </div>
          </div>
        </div>
        <div class="team" v-show="active2==2">
          <div class="teamBox">
            <el-carousel class="teamList" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(i,e) in team" :key="e">
                <div class="teamItem" v-for="(item,index) in i" :key="index">
                  <img :src="item.head" alt="" v-image-preview>
                  <div>
                    <p class="teamName1">{{item.username}}</p>
                    <p class="teamName2">{{item.job_grade}}</p>
                    <div class="teamName3">
                      <p v-for="(item2,index2) in item.position" :key="index2">{{item2}}</p>
                    </div>
                  </div>
                </div>
                </el-carousel-item>
            </el-carousel>
          </div>
          <div class="teamTitle">
            <span>技术团队 - </span>
            <span class="textFamily">Technical team</span>
          </div>
        </div>
        <div class="result" v-show="active2==3">
          <div class="resultTitle1">
            <span style="color:#fff;font-family:'Times New Roman'">R & D</span>
            <span class="textFamily">achievements</span>
          </div>
          <div class="resultTitle2">
            <div class="resultTitle21">研发成果</div>
            <div class="resultBtn">
              <div :class="{active2:active3==1}" @click="toResult(1)">标准规范</div>
              <div :class="{active2:active3==2}" @click="toResult(2)">专利技术</div>
              <div :class="{active2:active3==3}" @click="toResult(3)">科技奖励</div>
            </div>
          </div>
          <div class="result1" v-if="active3==1">
            <el-carousel class="relList" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(i,e) in result" :key="e">
                <div class="relItem" v-for="(item,index) in i" :key="index">
                  <img :src="item.achievement_image" alt="" v-image-preview>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="result2" v-if="active3==2">
            <el-carousel class="relList" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(i,e) in result" :key="e">
                <div class="relItem" v-for="(item,index) in i" :key="index">
                  <img :src="item.achievement_image" alt="" v-image-preview>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="result3" v-if="active3==3">
            <el-carousel class="relList relList2" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(i,e) in result" :key="e">
                <div class="relItem relItem2" v-for="(item,index) in i" :key="index">
                  <img :src="item.achievement_image" alt="" v-image-preview>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="content honour" v-show="active==5">
         <div class="honTitle1">
            <span class="textFamily">Honor & </span>
            <span style="color: #1E90DC;font-family:'Times New Roman'">Qualification</span>
          </div>
          <div class="honTitle2">荣誉资质</div>
          <div class="honList">
            <el-carousel class="relList relList2" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(i,e) in honour" :key="e">
                <div class="relItem relItem2" v-for="(item,index) in i" :key="index">
                  <img :src="item.honor_image" alt="" v-image-preview>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'know',
  components: {
    Top,
    Bottom,
  },
  data() {
    return {
      info:'',
      active:1,
      active2:1,
      active3:1,
      culture:'',
      plate:'',
      team:[],
      result:[],
      honour:[],
    };
  },
  mounted() {
    this.getInfo()
    // this.getCulture()
    // this.getPlate()
    // this.getTeam()
    // this.getResult(1)
    // this.getHonour()
  },
  methods: {
    // 获取企业信息
    getInfo(){
      this.startLoading()
      this.axios.post("/app/Platform/PlatformInfo").then(res => {
        if (res.data.code === 1) {
          this.info = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    sliceArray(array, size) {
      var result = [];  
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
          var start = x * size;
          var end = start + size;
          result.push(array.slice(start, end));
      }
      return result;
     
  },
    toContent(i){
      this.active = i
      this.active2 = 1
      if(i==3){
        this.getCulture()
      }else if(i==4){
        this.getPlate()
      }else if(i==5){
        this.getHonour()
      }
    },
    toContent2(i){
      this.active2 = i
      if(i==1){
        this.getPlate()
      }else if(i==2){
        this.getTeam()
      }else{
        this.getResult(1)
      }
    },
    // 获取文化港力信息
    getCulture(){
      this.startLoading()
      this.axios.post("/app/Platform/platformCulture").then(res => {
        if (res.data.code === 1) {
          this.culture = res.data.data.culture_image;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },

    // 获取研发平台信息
    getPlate(){
      this.startLoading()
      this.axios.post("/app/Platform/developmentPlatform").then(res => {
        if (res.data.code === 1) {
          this.plate = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },

    // 获取专业团队信息
    getTeam(){
      this.startLoading()
      this.axios.post("/app/Platform/platTeamList").then(res => {
        if (res.data.code === 1) {
          let arr = res.data.data;
          this.team = this.sliceArray(arr,8)
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },

    // 获取研发成果信息
    getResult(i){
      this.startLoading()
      this.axios.post("/app/Platform/achievementInfoList",{
        type:i
      }).then(res => {
        if (res.data.code === 1) {
          let arr = res.data.data;
          if(i==3){
            this.result = this.sliceArray(arr,8)
          }else{
            this.result = this.sliceArray(arr,5)
          }
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toResult(i){
      this.active3 = i
      this.getResult(i)
    },

    // 获取荣誉资质信息
    getHonour(){
      this.startLoading()
      this.axios.post("/app/Platform/honorInfoList").then(res => {
        if (res.data.code === 1) {
          let arr = res.data.data;
          this.honour = this.sliceArray(arr,8)
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
  }
}
</script>

<style scoped>
.know{
  padding: 4.167rem 0 6.25rem 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  font-family: Microsoft YaHei;
}
.knowBox{
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/bg.jpg');
  background-size: 100% 100%;
  overflow-y: scroll
}
.nav{
  width: 7rem;
  height: 14.6rem;
  position: fixed;
  left: 6.35rem;
  top: 15.4rem;
  display: flex;
  align-items: center;
}
.nav2{
  height: 21.77rem;
}
.navImg1{
  width: 6.25rem;
  height: 12.8rem;
}
.navImg2{
  width: 6.25rem;
  height: 19.9rem;
}
.navList{
  height: 14.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navList2{
  height: 21.77rem!important;
}
.navItem{
  width: 6.25rem;
  height: 2.08rem;
  border: 0.05rem solid #1E90DC;
  line-height: 2.08rem;
  text-align: center;
  color: #1E90DC;
  cursor: pointer;
}
.navSkill{
  width: 6.25rem;
  height: 9.58rem;
}
.navSkillTxt{
  width: 6.25rem;
  display: flex;
}
.navSkillTxt img{
  width: 0.52rem;
  height: 6.35rem;
  margin-left: 0.52rem
}
.navSkillList{
  width: 5.2rem;
  height: 6.67rem;
  margin-top: 0.52rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navSkillList div{
  width: 5.2rem;
  height: 1.875rem;
  border: 0.05rem solid #1E90DC;
  line-height: 1.875rem;
  text-align: center;
  color: #1E90DC;
  cursor: pointer;
}
.active{
  background: #1E90DC;
  color: #ffffff!important
}
.content{
  width: 67.7rem;
  /* height: 100%; */
  margin: 0 auto;
  padding-bottom: 2rem;
  padding-top: 2.5rem;
}
.about{
  display: flex;
  justify-content: space-between;
}
.aboutLeft{
  width: 21.88rem;
  height: 36.45rem;
  padding-top: 3.07rem;
  background-image: url('../assets/img/kuang1.png');
  background-size: 100% 100%; 
}
.aboutLeft img {
  width: 25rem;
  height: 31.25rem;
  margin-left: 2.8rem
}
.aboutRight{
  width: 36.45rem;
}
.aboutTitle1{
  font-size: 2.6rem;
  font-family: 'Times New Roman'!important;
  font-weight: bold;
  /* color: #1E90DC; */
  color: #ffffff
}
.aboutTitle2{
  font-size: 2.2rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 0.5rem
}
.line{
  width: 3.12rem;
  height: 0.52rem;
  background: #1E90DC;
  margin: 1rem 0;
}
.aboutTxt{
  color: #FFFFFF;
  background: rgba(30, 144, 220, 0.5);
  border: 0.104rem solid #1E90DC;
  padding: 0.5rem;
}
.strTitle1 span{
  font-size: 3.125rem;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  margin-right: 1rem
}
.strTitl2{
  font-size: 2.6rem;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
}
.strImg{
  width: 67.7rem;
  margin-top: 3.13rem
}
.culTitle{
  width: 100%;
  height: 6.6rem;
  line-height: 6.6rem;
  background-image: url('../assets/img/kuang2.png');
  background-size: 23.4rem auto;
  background-repeat: no-repeat;
  text-align: right;
}
.culTitle span{
  font-size: 3.125rem;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  margin-right: 1rem
}
.culImg{
  width: 67.7rem;
  height: 32.3rem;
}
.culImg img{
  width: 100%;
  height: 100%;
}
.plateTitle1 span{
  font-size: 2.6rem;
  font-family: Arial;
  font-weight: bold;
  color: #F5F5F5;
  margin-right: 1rem
}
.plateTitle2{
  font-size: 2.6rem;
  font-family: Microsoft YaHei;
  color: #F5F5F5;
  margin-bottom: 2rem
}
.plateItem{
  width: 21.2rem;
  height: 15rem;
  border: 0.42rem solid #1E90DC;
  display: inline-block;
  margin-right: 1.2rem;
  margin-bottom: 1.5rem;
}
.plateItem img{
  width: 20.3rem;
  height: 14rem;
}
.teamTitle{
  text-align:right;
  margin-bottom: 1rem
}
.teamTitle span{
  font-size: 3rem;
  font-family: Arial;
  font-weight: bold;
  color: #1E90DC;
  margin-left: 1rem;
}
.teamBox{
  width: 100%;
  height: 42rem;
}
.teamItem{
  width: 14.58rem;
  height: 18rem;
  text-align:center;
  /* display: inline-block; */
  margin-right: 2.2rem;
  margin-bottom: 1rem
}
.teamItem img{
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
}
.teamItem>div{
  height: 16rem;
  margin-top: -4.3rem;
  background-image: url('../assets/img/team1.png');
  background-size: 100% 100%;
  padding-top: 5.2rem;
  color: #ffffff;
  font-size: 0.83rem
}
.teamName1{
  font-size:1.56rem;
  margin-bottom: 0.2rem
}
.teamName3{
  display: inline-block;
  margin-top: 1.04rem;
  text-align:left;
  line-height:1.56rem
}
.teamBox /deep/.el-carousel__container{
  height: 42rem;
}
.teamBox /deep/.el-carousel__item{
  display: flex;
  flex-wrap:wrap;
  /* justify-content: space-between; */
  padding-left: 0.5rem
}
.teamBox /deep/.el-carousel__button{
  width: 4.17rem;
  height: 0.83rem;
  background: #1E90DC;
  border-radius: 2.6rem;
  background-color: #1E90DC
}
.resultTitle1 span{
  font-size: 3rem;
  font-family: Arial;
  font-weight: bold;
  color: #1E90DC;
  margin-right: 1rem
}
.resultTitle2{
  display: flex;
  justify-content: space-between;
}
.resultTitle21{
  font-size: 2.6rem;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
}
.resultBtn{
  display: flex;
  align-items: center
}
.resultBtn div{
  width: 6.25rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align:center;
  /* border: 0.05rem solid #1E90DC; */
  border: 0.05rem solid #ffffff;
  border-radius: 1.2rem;
  /* color: #1E90DC; */
  color: #ffffff;
  margin-left: 1rem;
  cursor: pointer;
}
.active2{
  background-color: #ffffff;
  color: #1E90DC!important
}
.relList{
  width: 100%;
  height: 24rem;
  margin-top: 4rem
}
.relList2{
  width: 100%;
  height: 28rem;
}
.relItem{
  /* width: 15.78rem;
  height: 11.5rem; */
  width: 12rem;
  height: 16.45rem;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  border: 0.26rem solid #1E90DC;
}
.relItem img{
  /* width: 15.26rem;
  height: 11rem; */
  width: 11.48rem;
  height: 15.93rem;
}
.relItem2{
  width: 15.78rem;
  height: 11.5rem;
}
.relItem2 img{
  width: 15.26rem;
  height: 11rem;
}
.relList /deep/.el-carousel__container{
  height: 24rem;
}
.relList2 /deep/.el-carousel__container{
  height: 28rem;
}
.relList /deep/.el-carousel__item{
  /* display: flex;
  flex-wrap:wrap;
  justify-content: space-between; */
  padding-left: 0.5rem
}
.relList /deep/.el-carousel__button{
  width: 4.17rem;
  height: 0.83rem;
  background: #1E90DC;
  border-radius: 2.6rem;
  background-color: #1E90DC
}
.honTitle1 span{
  font-size: 3rem;
  font-family: Arial;
  font-weight: bold;
  color: #fff;
  margin-right: 1rem;
}
.honTitle2{
  font-size: 2.6rem;
  font-family: Microsoft YaHei;
  color: #F5F5F5;
  margin-bottom: 1rem
}
</style>
