<template>
  <div class="home">
    <Top active='1'/>
    <el-carousel class="banner">
      <el-carousel-item v-for="(item,index) in img" :key="index">
        <img :src="item.banner" alt="">
      </el-carousel-item>
    </el-carousel>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { setTimeout } from 'timers';
export default {
  name: 'home',
  components: {
    Top,
    Bottom
  },
  data() {
    return {
      img:[
        require('../assets/img/banner1.png'),
        require('../assets/img/banner2.png'),
        require('../assets/img/banner3.png'),
      ],
      info:''
    };
  },
  mounted() {
    this.getData()
    // this.getInfo()
  },
  methods: {
    // 获取banner图片
    getData(){
      this.startLoading()
      this.axios.post("/app/Banner/bannerList").then(res => {
        if (res.data.code === 1) {
          this.img = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    
    // 获取企业信息
    getInfo(){
      this.startLoading()
      this.axios.post("/app/Platform/PlatformInfo").then(res => {
        if (res.data.code === 1) {
          this.info = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    }
  }
}
</script>

<style scoped>
.home{
  padding: 4.167rem 0 6.25rem 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
.banner{
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color:pink */
}
.banner /deep/.el-carousel__container{
  width: 100%;
  height: 100%;
}
.banner /deep/.el-carousel__item img{
  width: 100%;
  height: 100%;
}
.banner /deep/.el-carousel__arrow{
  width: 8rem;
  height: 8rem;
}
.banner /deep/.el-carousel__arrow i{
  font-size: 4rem!important
}
.banner /deep/.el-carousel__button{
  width: 3rem;
  height: 0.5rem;
  border-radius: 1.5rem;
}
</style>

