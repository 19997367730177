import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Know from '../views/know.vue'
import Solution from '../views/solution.vue'
import News from '../views/news.vue'
import Relation from '../views/relation.vue'
import Institute from '../views/institute.vue'
import Join from '../views/join.vue'
import SolutionList from '../views/solutionList.vue'
import SolutionDetail from '../views/solutionDetail.vue'
import NewsDetail from '../views/newsDetail.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
  {
    path: '/',
    redirect:'/home'
  },{
    path: '/home',
    name: 'home',
    component: Home,
    meta:{title: '首页'}
  }
  ,{
    path: '/know',
    name: 'know',
    component: Know,
    meta:{title: '了解港力'}
  },{
    path: '/solution',
    name: 'solution',
    component: Solution,
    meta:{title: '解决方案'}
  },{
    path: '/news',
    name: 'news',
    component: News,
    meta:{title: '新闻中心'}
  },{
    path: '/relation',
    name: 'relation',
    component: Relation,
    meta:{title: '投资者关系'}
  },{
    path: '/institute',
    name: 'institute',
    component: Institute,
    meta:{title: '三江研究院'}
  },{
    path: '/join',
    name: 'join',
    component: Join,
    meta:{title: '加入港力'}
  },{
    path: '/solutionList',
    name: 'solutionList',
    component: SolutionList
  },{
    path: '/solutionDetail',
    name: 'solutionDetail',
    component: SolutionDetail
  },{
    path: '/newsDetail',
    name: 'newsDetail',
    component: NewsDetail
  },
{
  path: '/notice',
  name: 'notice',
  component: () => import('../views/notice.vue'),
  meta:{title: '公告公示'}
},
]
})

export default router
