<template>
  <div class="solution">
    <Top  active='3'/>
    <div class="solBox">
      <div class="solList" >
        <div class="solItem" v-for="(item,index) in solution.slice(0,4)" :key="index" @click="toItem(item.id)">
          <img :src="item.logo_image" alt="">
          <p>{{item.title}}</p>
        </div>
        <div class="solItem solItem2"  >
          <img  :src="solution[4].logo_image" alt="">
          <p>{{solution[4].title}}</p>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'solution',
  components: {
    Top,
    Bottom
  },
  data() {
    return{
      solution:[]
    }
  },
  mounted() {
    this.getSolution()
  },
  methods: {
    toItem(i){
      this.$router.push({path:'solutionList',query:{id:i}})
    },

    // 获取解决方案列表
    getSolution(){
      this.startLoading()
      this.axios.post("/app/Solution/solutionTypeList").then(res => {
        if (res.data.code === 1) {
          this.solution = res.data.data;
          console.log(this.solution[4].title)
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
  }
}
</script>

<style scoped>
  .solution{
    padding: 4.167rem 0 6.25rem 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
  }
  .solBox{
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solList{
    display: flex;
    width: 67.7rem;
    height: 25rem;
    justify-content: space-between;
    align-items: center
  }
  .solItem{
    width: 12.5rem;
    height: 20.8rem;
    transition: width 0.5s , height 0.5s;
    background-color: #1E90DC;
    text-align:center;
    padding-top:0.52rem;
    display: inline-block;
    cursor: pointer;
  }
  .solItem2{
    cursor:default
  }
  .solItem:hover{
    width: 14.06rem;
    height: 23.44rem;
  }
  .solItem img{
    width: 11.46rem;
    height: 17.19rem;
    transition: width 0.5s , height 0.5s;
  }
  .solItem img:hover{
    width:13rem;
    height: 19.53rem;
  }
  .solItem p{
    font-size: 1.04rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
  }
</style>

