<template>
  <div class="bottom">
    <div class="bottomBox">
        <div class="left">
            <div class="left1">
                <span style='margin-right: 4rem'>电 话：{{info.tel}}</span>
                <span>地 址：{{info.address}}</span>
            </div>
            <div>{{info.copyright}}</div>
        </div>
        <div class="right">
            <div class="right1" @click="toOut">
                <div>{{info.reference}}</div>
                <!-- <div>工商备案信息：{{info.reference_info}}</div> -->
            </div>
            <img class="qrCode" :src="info.qr_code" alt="" v-image-preview>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  data() {
    return{
      info:''
    }
  },
  mounted() {
    this.getInfo()
  },
  methods:{
    // 获取企业信息
    getInfo(){
      this.axios.post("/app/Platform/PlatformInfo").then(res => {
        if (res.data.code === 1) {
          this.info = res.data.data;
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toOut(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style scoped>
.bottom {
  width: 100rem;
  height: 6.25rem;
  background-color: #17355D;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
.bottomBox {
  width: 67.7rem;
  height: 6.25rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.bottomBox div,.bottomBox span{
    font-size: 0.83rem;
}
.left{
    height: 4.43rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.qrCode{
    width: 4.43rem;
    height: 4.43rem;
}
.right{
    display: flex;
}
.right1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
}
</style>

