<template>
  <div class="newsDetail">
    <Top active='4'/>
      <div class="newsBox">
        <div class="title" v-if="type==1">{{data.dynamic_title}}</div>
        <div class="title" v-else>{{data.activity_title}}</div>
        <div class="content">
          <div class="time">
            <span style="margin-right:2rem" v-if="data.pub_user">发布者：{{data.pub_user}}</span>
            <span>时间：{{data.create_at}}</span>
          </div>
          <div class="intro">
            <div v-for="(item,index) in content" :key="index">
              <div class="txt" v-if="item.type==1" v-html="item.content"></div>
              <img v-if="item.type==2" :src="item.content" v-image-preview alt="">
            </div>
          </div>
          <!-- <div class="intro" v-if="type==1" v-html="data.dynamic_intro"></div>
          <div class="intro" v-else v-html="data.activity_intro"></div> -->
          <div class="url">新闻链接：
            <span style="cursor:pointer" v-if="type==1&&data.dynamic_url" @click="toUrl(data.dynamic_url)"><span class="urlTxt">{{data.dynamic_url}}</span></span>
            <span style="cursor:pointer" v-if="type==2&&data.activity_url" @click="toUrl(data.activity_url)"><span class="urlTxt">{{data.activity_url}}</span></span>
          </div>
        </div>
        
      </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'newsDetail',
  data() {
    return {
      id:this.$route.query.id,
      type:this.$route.query.type,
      data:'',
      content:[]
    }
  },
  components: {
    Top,
    Bottom
  },
  mounted(){
      this.getData()
  },
  methods: {
    getData(){
      this.startLoading()
        if(this.type==1){
            this.axios.post("/app/Company/companyDynamicDetail",{
                dynamic_id:this.id
            }).then(res => {
                if (res.data.code === 1) {
                    this.data = res.data.data
                    this.content = this.data.content
                    this.endLoading()
                } else {
                    this.$message(res.data.msg);
                }
            });
        }else{
            this.axios.post("/app/Company/companyActivityDetail",{
                activity_id:this.id
            }).then(res => {
                if (res.data.code === 1) {
                    this.data = res.data.data
                    this.content = this.data.content
                    this.endLoading()
                } else {
                    this.$message(res.data.msg);
                }
            });
        }
    },
    toUrl(i){
      window.open(i)
    }
  }
}
</script>

<style scoped>
  .newsDetail{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    padding: 4.167rem 0 6.25rem 0;
    overflow-y: scroll
  }
  .newsBox{
    width: 65rem;
    margin: 5rem auto 2rem auto;
    
  }
  .title{
    width: 100%;
    height: 2.5em;
    line-height: 2.5em;
    background-color: #054490;
    font-size:1.8rem;
    text-align: center;
    color:#ffffff;
    text-shadow: #000000 0.25rem 0.25rem 0.2rem;
  }
  .time{
      color: #666666;
      border-bottom: 0.15rem solid #000000;
      height: 3.5rem;
      line-height: 3.5rem;
      text-align: center;
      margin-bottom:1.5rem;
  }
  .content{
    width: 100%;
    color: #333333;
    background-color: rgba(225,225,225,0.1);
    padding: 2rem 5rem;
    box-shadow:0.15rem 0.15rem 0.9rem rgba(0,0,0,0.22);
    line-height:2rem;
  }
  .intro>div{
    margin: 2rem 0;
  }
  .txt{
    text-indent: 1.8rem
  }
  .intro img{
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  .url{
    width: 100%;
    margin-top: 1rem;
    color: #666666;
    font-size: 1rem;
  }
  .urlTxt{
    white-space:normal;
    word-break:break-all
  }
</style>

