import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
Vue.prototype.axios = axios;

import ElementUI,{ Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI).use(Loading);

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

Vue.prototype.startLoading = function() {    //使用Element loading-start 方法
  const loading = this.$loading({
      lock: true,
      text: 'loading...',
      background: 'rgba(0, 0, 0, 0.7)'
  })
}
Vue.prototype.endLoading = function() {    //使用Element loading-close 方法
  this.$loading().close()
}

Vue.prototype.$message = function(msg) {
  ElementUI.Message({message: msg, duration: 1000 ,offset:300})
}

if (navigator.userAgent.indexOf('Mobile')>-1) {  
    window.location.href = 'http://ccqgl.com/h5'
}

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')