<template>
  <div class="top">
    <div class="topBox" >
      <img class="logo" src="../assets/img/logo.png" alt="" >
      <div class="navList"  >
        <div :class="{isActive:active==1}" @click="toPage(1)">网站首页</div>
        <div :class="{isActive:active==2}" @click="toPage(2)">了解港力</div>
        <div :class="{isActive:active==3}" @click="toPage(3)">解决方案</div>
        <div :class="{isActive:active==4}" @click="toPage(4)">新闻中心</div>
        <div :class="{isActive:active==5}" @click="toPage(5)">投资者关系</div>
        <div :class="{isActive:active==6}" @click="toPage(6)">三江研究院</div>
        <div :class="{isActive:active==7}" @click="toPage(7)">加入港力</div>
        <div  :class="{isActive:active==8}" @click="toPage(8)">公告公示</div>
      </div>
      <div class="code">股票代码：{{info.stock_code}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
  props: ["active"],
  data() {
    return {
      info:''
    };
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    toPage(i){
      let pageObj={
        1:'/home',
        2:'/know',
        3:'/solution',
        4:'/news',
        5:'/relation',
        6:'/institute',
        7:'/join',
        8:'/notice'
      }
      this.$router.push(pageObj[i])
    },
    // 获取企业信息
    getInfo(){
      this.axios.post("/app/Platform/PlatformInfo").then(res => {
        if (res.data.code === 1) {
          this.info = res.data.data;
        } else {
          this.$message(res.data.msg);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.top {
  width: 100rem;
  height: 4.167rem;
  background-color: #17355D;
  position: fixed;
  top: 0;
  z-index: 999;
}
.topBox {
  width: 79rem;
  height: 4.167rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between
}
.logo {
  width: 9.16rem;
  height: 2.34rem;
}
.navList {
  width: 48rem;
  color: #ffffff;
  white-space: nowrap;
  display: flex;
  justify-content: space-between
}
.navList div {
  text-align: center;
  height: 4.167rem;
  line-height: 3.959rem;
  cursor: pointer;
}
.isActive {
  color:#1E90DC;
  border-bottom: 0.208rem solid #1E90DC;
}
.code{
  color: #fff
}
</style>
