<template>
  <div class="news">
    <Top  active='4'/>
    <div class="newsBox">
      <div class="nav">
        <img class="navImg" src="../assets/img/zhi2.png" alt="">
        <div class="navList">
          <div class="navItem" :class="{active:active==1}" @click="toItem(1)">企业动态</div>
          <div class="navItem" :class="{active:active==2}" @click="toItem(2)">文化活动</div>
        </div>
      </div>
      <div class="content news2" v-if="active==1">
        <div class="title">
          <div class="title1">企业动态</div>
          <div class="title2">
            <span class="textFamily">Enterprise</span>
            <span class="textFamily">news</span>
          </div>
        </div>
        <div class="news2Box">
          <div class="newsItem" v-for="(item,index) in news" :key="index" @click="toDetail(item.id)">
            <div>
              <img :src="item.dynamic_image" alt="">
              <div class="newsItemTxt">
                <p class="newsItemTxt1">{{item.dynamic_title}}</p>
                <!-- <p class="newsItemTxt2">{{item.dynamic_intro}}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content activity" v-if="active==2">
        <div class="title">
          <div class="title1">文化活动</div>
          <div class="title2">
            <span class="textFamily">Cultural</span>
            <span class="textFamily">activities</span>
          </div>
        </div>
        <div class="activityBox" v-for="(i,e) in activity" :key="e">
          <div class="activityLeft" @click="toDetail(i[0].id)">
            <img :src="i[0].activity_image" alt="">
            <p>{{i[0].activity_title}}</p>
          </div>
          <div class="activityRight">
            <div class="rightItem" v-for="(item,index) in i.slice(1,4)" :key="index" @click="toDetail(item.id)">
              <img :src="item.activity_image" alt="">
              <div class="rightItemTxt">
                <p>{{item.activity_title}}</p>
                <!-- <p class="rightItemTxt2">{{item.activity_intro}}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
export default {
  name: 'news',
  components: {
    Top,
    Bottom
  },
  data() {
    return {
      active:1,
      news:[],
      activity:[]
    }
  },
  mounted() {
    this.getNews()
    // this.getActivity()
  },
  methods: {
    //把数组分为若干个子数组
    sliceArray(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
          var start = x * size;
          var end = start + size;
          result.push(array.slice(start, end));
      }
      return result;
  },
    // 获取企业动态数据
    getNews(){
      this.startLoading()
      this.axios.post("/app/Company/companyDynamicList").then(res => {
        if (res.data.code === 1) {
          this.news = res.data.data;
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    toItem(i){
      this.active=i
      if(i==1){
        this.getNews()
      }else(
        this.getActivity()
      )
    },
    // 获取企业活动数据
    getActivity(){
      this.startLoading()
      this.axios.post("/app/Company/companyActivityList").then(res => {
        if (res.data.code === 1) {
          let arr = res.data.data;
          this.activity = this.sliceArray(arr,4)
          this.endLoading()
        } else {
          this.$message(res.data.msg);
        }
      });
    },

    // 去详情页
    toDetail(id){
      // window.open(url)
      this.$router.push({path:'/newsDetail',query:{
        id:id,
        type:this.active
      }})
    },
  }
}
</script>

<style scoped>
  .news{
    padding: 4.167rem 0 6.25rem 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    font-family: Microsoft YaHei;
    color: #ffffff
  }
  .newsBox{
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/bg.jpg');
    background-size: 100% 100%;
    overflow-y: scroll;
    padding-bottom: 2rem
  }
  .content{
    width: 67.7rem;
    /* height: 100%; */
    margin: 0 auto;
    padding-top: 2.5rem;
  }
  .nav{
    width: 7rem;
    height: 5.2rem;
    position: fixed;
    left: 6.35rem;
    top: 15.4rem;
    display: flex;
    align-items: center;
  }
  .navImg{
    width: 6.25rem;
    height: 3.23rem;
  }
  .navList{
    height: 5.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .navItem{
    width: 6.25rem;
    height: 2.08rem;
    border: 0.05rem solid #1E90DC;
    line-height: 2.08rem;
    text-align: center;
    color: #1E90DC;
    cursor: pointer;
  }
  .active{
    background: #1E90DC;
    color: #ffffff!important
  }
  .title{
    text-align:center;
    margin-bottom: 2rem
  }
  .title1{
    font-size: 2.6rem;
    color: #FFFFFF;
    margin-bottom: 1rem
  }
  .title2 span{
    font-size: 3.13rem;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
    margin-right: 2rem
  }
  .news2Box,.activityBox{
    width: 67.7rem;
  }
  .newsItem{
    display: inline-block;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
  }
  .newsItem>div{
    padding-right: 1.4rem;
    color: #ffffff;
    width: 32.2rem;
    height: 8.6rem;
    background: rgba(30, 144, 220, 0.5);
    border: 0.104rem solid #1E90DC;
    display: flex;
  }
  .newsItem img{
    width: 10.83rem;
    height: 8.392rem;
    display: block;
  }
  .newsItemTxt{
    margin-left: 0.83rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.83rem
  }
  .newsItemTxt1{
    font-size: 1.04rem;
    font-weight: bold;
  }
  .newsItemTxt2{
    font-size: 0.83rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .activityBox{
    width: 100%;
    height: 27.08rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem
  }
  .activityLeft{
    width: 23.44rem;
    height: 27.08rem;
    background: rgba(30,144,220, 0.5);
    text-align: center;
    padding-top:0.52rem;
    display: inline-block;
    cursor: pointer;
  }
  .activityLeft img{
    width: 22.4rem;
    height: 22.4rem;
  }
  .activityLeft p{
    font-size: 1.04rem;
    font-weight: bold;
    margin-top: 1rem
  }
  .activityRight{
    width: 42.9rem;
    height: 27.08rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
  .rightItem{
    width: 42.9rem;
    height: 8.33rem;
    background: rgba(30, 144, 220, 0.5);
    border: 0.104rem solid #1E90DC;
    display: flex;
    padding-right: 1.5rem;
    cursor: pointer;
  }
  .rightItem img{
    width: 8.122rem;
    height: 8.122rem;
  }
  .rightItemTxt{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 1.5rem
  }
  .rightItemTxt2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>

